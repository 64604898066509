import React from 'react';
import { useTranslation } from 'react-i18next';
import FeatureCard from './FeatureCard';
import styles from './../css/FeaturesSection.module.css';

const FeaturesSection = () => {
  const { t } = useTranslation();

  const featuresData = [
    { title: t('feature1t'), description: t('feature1d') },
    { title: t('feature2t'), description: t('feature2d') },
    { title: t('feature3t'), description: t('feature3d') },
    { title: t('feature4t'), description: t('feature4d') },
    { title: t('feature5t'), description: t('feature5d') }
  ];

  return (
    <section className={styles.featuresSection}>
      <h2 className={styles.sectionTitle}>{t('featuresTitle')}</h2>
      <h3 className={styles.sectionSubtitle}>{t('featuresSubtitle')}</h3>
      <div className={styles.featureCards}>
        {featuresData.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
    </section>
  );
};

export default FeaturesSection;
