import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './../css/Footer.module.css';
import logo from './../img/itcs-logo.png';

const Footer = () => {
  const { t } = useTranslation();

  const navItems = [
    { name: t('footerLink1'), path: '/' },
    { name: t('footerLink2'), path: '/products' },
    { name: t('footerLink3'), path: '/oa' },
    { name: t('footerLink4'), path: '/articles' },
    { name: t('contact'), path: '/contact' }
  ];

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.logo}>
          <img loading="lazy" src={logo} className={styles.logoIcon} alt="" />
          <br />
          <div className={styles.logoText}>IT Consulting Services d.o.o.</div>
        </div>
        <nav className={styles.navigation}>
          {navItems.map((item) => (
            <a key={item.path} href={item.path}>{item.name}</a>
          ))}
        </nav>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/9c40f9a9217412202e85bfc37da1fa362f92f61ffa1b721a8f7cb54695feb589?placeholderIfAbsent=true&apiKey=de87054b713149a7a6392d770b91d54c"
          className={styles.divider}
          alt=""
        />
        <div className={styles.copyright}>
          {t('copyright')}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
