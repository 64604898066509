import React from 'react';
import styles from './../css/ContactPage.module.css';
import {LocationOn, Phone, Email} from '@mui/icons-material';
import Navigation from "./Navigation";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';

function ContactPage() {

    const { t } = useTranslation();

    return (
        <div className={styles.contactPage}>
            <Navigation/>
            <div className={styles.hero}>
                <h1 className={styles.heroTitle}>{t('contact')}</h1>
                <p className={styles.heroSubtitle}>{t('contactSubtitle')}</p>
            </div>
            <div className={styles.contentContainer}>
                <h2 className={styles.sectionTitle}>{t('hereForYou')}</h2>
                <p className={styles.sectionText}>
                    {t('contactDesc')}
                </p>

                <div className={styles.infoContainer}>
                    <div className={styles.contactBox}>
                        <h3 className={styles.boxTitle}>{t('visitUs')}</h3>
                        <div className={styles.boxContent}>
                            <p className={styles.location}><strong>Karlovac, Hrvatska</strong></p>
                            <div className={styles.contactItem}>
                                <LocationOn className={styles.icon}/>
                                <span>{t('Address')}</span>
                            </div>
                            <div className={styles.contactItem}>
                                <Phone className={styles.icon}/>
                                <a href="tel:+385911280108">{t('phoneNumber')}</a>
                            </div>
                            <div className={styles.contactItem}>
                                <Email className={styles.icon}/>
                                <a href="mailto:info@itcs.hr">{t('emailInfo')}</a>
                            </div>
                        </div>
                    </div>

                    <div className={styles.mapContainer}>
                        <iframe
                            title="IT Consulting Services Location"
                            src="
                            https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.268879752055!2d15.570089013181507!3d45.48452997095375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47643d5fab20e6c7%3A0xc3d64eddadaa9087!2sIT%20Consulting%20Services%20d.o.o.!5e0!3m2!1shr!2sba!4v1731325401698!5m2!1shr!2sba"
                            width="100%"
                            height="300"
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </div>
                </div>
                <div className={styles.companyInfo}>
                    <p>

                        {t('company')}
                        <br/>
                        {t('number')}
                        <br/>
                        <br/>
                        {t('oib')}
                        <br/>
                        <br/>
                        {t('boardMember')}
                        <br/>
                        <br/>
                        {t('iban')}
                        <br/>
                        {t('raiffeisen')}
                        <br/>
                        {t('erste')}
                        <br/>
                        {t('zaba')}
                        <br/>
                        {t('otp')}
                    </p>
                </div>
            </div>
            <Footer/>

        </div>
    );
}

export default ContactPage;
