import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Box, TextField } from "@mui/material";
import styles from "./../../css/Articles.module.css";

const KnowledgeBaseCard = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedPost, setSelectedPost] = useState(null);
    const [selectedPostId, setSelectedPostId] = useState(null);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]); // Multi-select tag state
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await fetch(
                    "https://inp-b-prod.itcs.hr/knowledge_base_app/company-public-posts/1"
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();

                setPosts(data);

                // Extract unique tags
                const uniqueTags = Array.from(
                    new Set(data.flatMap((post) => post.tags.split(",").map((tag) => tag.trim())))
                );
                setTags(uniqueTags);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    const toggleTag = (tag) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter((t) => t !== tag));
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    const filteredPosts = posts
        .filter((post) => {
            const lowerCaseSearchTerm = searchTerm.toLowerCase();
            return (
                (post.title && post.title.toLowerCase().includes(lowerCaseSearchTerm)) ||
                (post.content && post.content.toLowerCase().includes(lowerCaseSearchTerm)) ||
                (post.tags &&
                    post.tags
                        .split(",")
                        .map((tag) => tag.trim().toLowerCase())
                        .some((tag) => tag.includes(lowerCaseSearchTerm)))
            );
        })
        .filter((post) => {
            if (selectedTags.length === 0) return true; // Show all if no tags selected
            const postTags = post.tags.split(",").map((tag) => tag.trim());
            return selectedTags.every((tag) => postTags.includes(tag));
        });

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className={styles.mainContainer} style={{ display: "flex", maxWidth: "1500px", margin: "0 auto" }}>
            {/* Lijeva strana - Pretraga, Tagovi i Popis članaka */}
            <div className={`${styles.leftContainer}`}>
                {/* Pretraga */}
<div style={{ marginBottom: "20px" }}>
    <TextField
        variant="outlined"
        placeholder="Pretraži članke..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        fullWidth
        InputProps={{
            style: {
                borderRadius: "30px",
                height: "40px", // Manja visina
                width:"375px"
            },
        }}
    />
</div>


                {/* Tagovi s horizontalnim scrollom i abecednim redoslijedom */}
<div
    style={{
        display: "flex",
        gap: "10px",
        flexDirection: "row",
        overflowX: "auto",
        padding: "10px 0",
        scrollbarWidth: "thin",
        scrollbarColor: "#F8D00D transparent",
        flexWrap: "wrap",
        width: "375px",
    }}
>
    {tags
        .slice() // Kreira kopiju niza kako ne bismo mijenjali original
        .sort((a, b) => a.localeCompare(b)) // Sortiranje po abecedi
        .map((tag) => (
            <div
                key={tag}
                onClick={() => toggleTag(tag)}
                style={{
                    backgroundColor: selectedTags.includes(tag) ? "#F8D00D" : "transparent",
                    color: selectedTags.includes(tag) ? "black" : "black",
                    border: "1px solid #F8D00D",
                    borderRadius: "12px",
                    padding: "2px 1px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    transition: "all 0.3s",
                    boxShadow: selectedTags.includes(tag)
                        ? "0 2px 5px rgba(0, 0, 0, 0.2)"
                        : "none",
                }}
            >
                {tag}
            </div>
        ))}
</div>

                {/* Popis članaka */}
                {filteredPosts.map((post) => (
                    <div className={styles.card} key={post.id} style={{ width:"375px"}}>
                        <Card
                            sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "20px",
                                border: post.id === selectedPostId ? "2px solid #F8D00D" : "2px solid #ddd",
                                boxShadow: post.id === selectedPostId ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
                                cursor: "pointer",
                                 width:"375px",
                                transition: "border-color 0.3s, box-shadow 0.3s",
                                "&:hover": {
                                    borderColor: "#F8D00D",
                                },
                            }}
                            onClick={() => {
                                setSelectedPost(post);
                                setSelectedPostId(post.id);
                            }}
                        >
                            <CardContent>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "baseline",
                                    }}
                                >
                                    <Typography
                                        gutterBottom
                                        sx={{ color: "text.secondary", fontSize: 14 }}
                                    >
                                        {`${post.date}`}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 1,
                                            marginTop: 1,
                                        }}
                                    >
                                        {post.tags.split(",").map((tag, index) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    backgroundColor: "#F8D00D",
                                                    color: "black",
                                                    padding: "2px 4px",
                                                    borderRadius: "12px",
                                                    fontSize: "10px",
                                                    fontWeight: "bold",
                                                    display: "inline-block",
                                                }}
                                            >
                                                {tag.trim()}
                                            </Box>
                                        ))}
                                    </Box>
                                </div>
                                <Typography variant="p" style={{ marginTop: "5px" }} component="div">
                                    {post.title}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                ))}
            </div>

            {/* Desna strana - Detalji članka */}
            <div className={`${styles.rightContainer}`}>
                {selectedPost ? (
                    <div>
                        <Typography variant="h4">{selectedPost.title}</Typography>
                        <Typography variant="body2" sx={{ marginTop: 1, color: "text.secondary" }}>
                            <strong>Autor:</strong> {selectedPost.posted_by_full_name}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: 1, color: "text.secondary" }}>
                            <strong>Kategorija:</strong> {selectedPost.category_name}
                        </Typography>

                        <Typography sx={{ marginTop: 2 }}>{selectedPost.content}</Typography>
                        <div dangerouslySetInnerHTML={{ __html: selectedPost.body }}></div>
                    </div>
                ) : (
                    <Typography variant="h6" sx={{ color: "text.secondary" }}>
                        Odaberite članak za pregled.
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default KnowledgeBaseCard;
