import React from 'react';
import {useTranslation} from 'react-i18next';
import styles from './../../css/OfficeAssistant.module.css';
import FeatureIcon from './FeatureIcon';
import Screenshot from './Screenshot';
import heroImage from './../../img/OA hero.png';
import noticeBoard from './../../img/Sekcija moduli - slike laptop/Oglasna ploca.png';
import documents from './../../img/Sekcija moduli - slike laptop/Dokumenti.png';
import table from './../../img/Sekcija moduli - slike laptop/Moj stol.png';
import vacation from './../../img/Sekcija moduli - slike laptop/Godisnji.png';
import projects from './../../img/Sekcija moduli - slike laptop/Projekti.png';
import base from './../../img/Sekcija moduli - slike laptop/Baza znanja.png';
import vehicles from './../../img/Sekcija moduli - slike laptop/Vozila.png';
import workHours from './../../img/Sekcija moduli - slike laptop/Radno vrijeme.png';
import hr from './../../img/Sekcija moduli - slike laptop/Kadrovska.png';
import trip from './../../img/Sekcija moduli - slike laptop/Putni nalozi.png';
import meeting from './../../img/Sekcija moduli - slike laptop/E sastanci.png';
import chat from './../../img/Sekcija moduli - slike laptop/Chat.png';
import date from './../../img/Sekcija moduli - slike laptop/Terminator.png';
import gallery from './../../img/Sekcija moduli - slike laptop/Galerija.png';
import notification from './../../img/Sekcija moduli - slike laptop/Podsjetnici.png';
import timeIcon from './../../img/Time icon.png';
import dashboardIcon from './../../img/Dashboard icon.png';
import performanceIcon from './../../img/Performance icon.png';
import dashboard from './../../img/Sekcija moduli - slike laptop/Kontrolna_Ploca2.png'
import constructis from './../../img/Sekcija moduli - slike laptop/Constructis.png'
import eLearning from './../../img/Sekcija moduli - slike laptop/E learning.png'
import events from './../../img/Sekcija moduli - slike laptop/Organizator.png'
import {Navigate} from "react-router-dom";
import Navigation from "../Navigation";
import Footer from "../Footer";
import oaHero from "./../../img/OA image.png"

const OfficeAssistant = () => {
    const {t} = useTranslation();

    const features = [
        {
            imgSrc: timeIcon,
            title: t('realTime'),
            description: t('timeDesc')
        },
        {
            imgSrc: dashboardIcon,
            title: t('dashboard'),
            description: t('dashboardDesc')
        },
        {
            imgSrc: performanceIcon,
            title: t('performance'),
            description: t('performanceDesc')
        }
    ];

    const screenshots = [
        {
            title: t('noticeBoard'),
            description: t('NoticeBoardDesc'),
            imgSrc: noticeBoard
        },
        {
            title: t('documents'),
            description: t('documentsDesc'),
            imgSrc: documents
        },
        {
            title: t('table'),
            description: t('tableDesc'),
            imgSrc: table
        },
        {
            title: t('vacation'),
            description: t('vacationDesc'),
            imgSrc: vacation
        },
        {
            title: t('projects'),
            description: t('projectsDesc'),
            imgSrc: projects
        },
        {
            title: t('knowledge'),
            description: t('knowledgeDesc'),
            imgSrc: base
        },
        {
            title: t('vehicles'),
            description: t('vehiclesDesc'),
            imgSrc: vehicles
        },
        {
            title: t('workHours'),
            description: t('workHoursDesc'),
            imgSrc: workHours
        },
        {
            title: t('hr'),
            description: t('hrDesc'),
            imgSrc: hr
        },
        {
            title: t('travel'),
            description: t('travelDesc'),
            imgSrc: trip
        },
        {
            title: t('meetings'),
            description: t('meetingsDesc'),
            imgSrc: meeting
        },
        {
            title: t('chat'),
            description: t('chatDesc'),
            imgSrc: chat
        },
        {
            title: t('terminator'),
            description: t('terminatorDesc'),
            imgSrc: date
        },
        {
            title: t('gallery'),
            description: t('galleryDesc'),
            imgSrc: gallery
        },
        {
            title: t('reminders'),
            description: t('remindersDesc'),
            imgSrc: notification
        },
        {
            title: t('events'),
            description: t('eventsDesc'),
            imgSrc: events
        },
        {
            title: t('dash'),
            description: t('dashDesc'),
            imgSrc: dashboard
        },
        {
            title: t('constructis'),
            description: t('constructisDesc'),
            imgSrc: constructis
        }, {
            title: t('learning'),
            description: t('learningDesc'),
            imgSrc: eLearning
        },
    ];

    return (
        <div className={styles.container}>
            <Navigation/>
            <header className={styles.hero}>

                <img src={oaHero} alt=""/>


                <div className={styles.oaText}>
                    <h1>{t('oa')}</h1>
                    <p>{t('oaDesc')}</p>
                    <a href="/contact" style={{textDecoration: "none", color: "black"}}>
                        <button>{t('demo')}</button>
                    </a>
                </div>
            </header>

            <section className={styles.features}>
                <h2>{t('featuresTitle')}</h2>
                <p>{t('featuresSubtitle')}</p>
                <div className={styles.featureList}>
                <div className={styles.list}>
                        {features.map((feature, index) => (
                            <FeatureIcon
                                key={index}
                                imgSrc={feature.imgSrc}
                                title={feature.title}
                                description={feature.description}
                            />
                        ))}
                    </div>
                </div>
            </section>

            <section className={styles.screenshots}>
                <div className={styles.screenshotList}>
                    {screenshots.map((screenshot, index) => (
                        <Screenshot
                            key={index}
                            title={screenshot.title}
                            description={screenshot.description}
                            imgSrc={screenshot.imgSrc}
                            isReversed={true}
                        />
                    ))}
                </div>
            </section>

            <Footer/>
        </div>
    );
};

export default OfficeAssistant;
