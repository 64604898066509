import React from "react"
import Articles from "./Articles";
import articlesImage from "./../../img/articlesImage.png"
import Navigation from "./../../Components/Navigation";
import styles from "./../../css/Articles.module.css"
import Footer from "../../Components/Footer";

export default function Blog(){
    return(

        <div>
            <Navigation/>
            {/*<div className={styles.header}>
                <div className={styles.headerContentWrapper}>
                    <div className={styles.text}>
                        <h2>Što je novo kod nas?</h2>
                        <h3>Pročitajte naše stručne članke</h3>
                    </div>
                    <div>
                        <img src={articlesImage} alt=""/>
                    </div>
                </div>
            </div>*/}
            <Articles/>
            <Footer/>


        </div>

    )
}